.RedButtons {
    display: block;
    position: relative;
    background-color: #D94F5B;
    font-family: Poppins, serif;
    font-size: 16px;
    text-align: center;
    color: white;
    min-width: 180px;
    letter-spacing: 2px;
    line-height: 44px;
    height: 44px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    /*padding: 5px;*/
}

.RedButtonsNext {
    display: block;
    position: relative;
    background-color: #D94F5B;
    font-family: Poppins, serif;
    font-size: 16px;
    text-align: center;
    padding-right: 30px;
    color: white;
    min-width: 180px;
    letter-spacing: 2px;
    line-height: 40.4px;
    height: 40.4px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    /*padding: 5px;*/
}

.TransparentBt {
    background-color: transparent !important;
    border: 2px solid #23A287 !important;
    color: #23A287 !important;
    box-sizing: border-box;
    line-height: 37px !important;
    height: 40px !important;
    /* text-align: center !important; */
    /* padding-right: 60px !important; */
}

.SelectBt {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
    background-color: white !important;
    border: 1px solid #23A287 !important;
    color: #23A287 !important;
}

.BTGear {
    padding-right: 25px !important;
}

.CancelSelectBt {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
    background-color: white !important;
    border: 1px solid #D94F5B !important;
    color: #D94F5B !important;
}


.TransparentBt * {
    fill: #23A287;
}

.TransparentBt > svg {
    width: 25px !important;
    height: 25px !important;
    right: 38px !important;
}

.TransparentClose {
    text-align: center !important;
    /* padding: 0 !important; */
    /* padding-right: 0 !important ; */
}

.RedButtonsNext > svg {
    position: absolute;
    display: block;
    width: 32px;
    height: 25px;
    top: 50%;
    transform: translate(0, -50%);
    right: 20%;
}

.RedButtonsSend {
    display: block;
    position: relative;
    background-color: #D94F5B;
    font-family: Poppins, serif;
    font-size: 16px;
    text-align: center;
    padding-right: 30px;
    color: white;
    min-width: 180px;
    letter-spacing: 2px;
    line-height: 38px;
    height: 40.4px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    /*padding: 5px;*/
}

.RedButtonsSend > svg {
    position: absolute;
    display: block;
    width: 38px;
    height: 43px;
    top: 50%;
    transform: translate(0, -50%);
    right: 20%;
}

.DarkGreenButtons {
    display: block;
    position: relative;
    background-color: transparent;
    border: 2px solid white;
    font-family: Poppins, serif;
    font-size: 16px;
    text-align: center;
    color: white;
    min-width: 180px;
    letter-spacing: 2px;
    height: 44px;
    line-height: 44px;
    border-radius: 10px;
    /*padding: 5px;*/
    cursor: pointer;
    text-decoration: none;
}

.DarkButtons {
    display: block;
    position: relative;
    background-color: #1D3138;
    font-family: Poppins, serif;
    font-size: 16px;
    text-align: center;
    color: white;
    min-width: 10.2vw;
    letter-spacing: 2px;
    height: 44px;
    line-height: 44px;
    border-radius: 10px;
    padding: 0 25px 0 25px;
    border: none;
    cursor: pointer;
}
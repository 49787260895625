* {
    margin: 0 0;
}

.Login_BackGround {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
}

.Register_BackGround {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
}

.RegisterBackgroundFinal {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #23A287;
    color: white;
}

.Login_BackGround > svg {
    position: absolute;
    display: block;
    width: 950%;
    height: 500%;
    opacity: 0.25;
    top: 118%;
    left: 18%;
    transform: translate(-50%, -50%) rotate(44.44deg);
}

.RegisterBackgroundFinal > svg {
    position: absolute;
    display: block;
    width: 546%;
    height: 136%;
    opacity: 0.75;
    top: 50%;
    left: 63%;
    transform: translate(-50%, -50%) rotate(0);
}

.Login_Window_image {
    /* 530.1px X 431.1px */
    position: absolute;
    display: block;
    width: 530.1px;
    height: 431.1px;
    left: -8%;
    top: 9%;
    transform-origin: 0 0;
    /* transform: scale(.9); */
}

.Login_Window {
    position: absolute;
    /*  749px X 541px  */
    width: 749px;
    height: 541px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* left: calc(50% - 749px/2 - 18.5px); */
    /* top: calc(50% - 541px/2 - 6px); */
    background: #1D3138;
    box-shadow: 7px 6px 24px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    /* transform: scale(.9); */
}

.Login_Window_inputs {
    display: flex;
    position: relative;
    padding-top: 14%;
    width: 360px;
    height: fit-content;
    flex-shrink: 0;
    float: right;
    right: 5%;
    top: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ForgotPassowordBT {
    display: block;
    position: relative;
    color: white;
    margin: 22px 0 22px 0;
    cursor: pointer;
    user-select: none;
}

.Login_Window_powerby {
    /* 183.6px X 38.7px */
    position: absolute;
    display: block;
    bottom: 10px;
    width: 183.6px;
    height: 38.7px;
    left: 15%;
    transform-origin: 0 0;
    /* transform: scale(.9); */
}

.RegisterBackground {
    width: 50%;
    height: 100%;
    background-color: #23A287;
}

.RegisterBackground > svg {
    position: absolute;
    width: 838%;
    height: 115%;
    left: 33%;
    top: 55%;
    opacity: 0.75;
    transform: translate(-50%, -50%) rotate(5.74deg);
}

.RegisterBackgroundText {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 175px;
    top: 50%;
    transform: translate(0, -50%);
    color: white;

    text-align: justify;
    text-justify: inter-word;
}

.RegisterBackgroundFinal h1 {
    /*margin-bottom: 20px;*/
    font-size: 64px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 800;
    /*margin-bottom: 84px;*/
}

.RegisterBackgroundFinal p {
    display: block;
    position: relative;
    width: 400px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 16px;
}

.RegisterBackgroundText h1 {
    margin-bottom: 20px;
    font-size: 64px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 800;
    margin-bottom: 84px;
}

.RegisterBackgroundText p {
    width: 70%;
    font-size: 16px;
    margin-bottom: 48px;
}

.RegisterBackgroundText b {
    width: 70%;
    font-size: 16px;
}


.RegisterBackgroundFinal b {
    width: 70%;
    font-size: 16px;
}

.RegisterForm {
    display: block;
    position: relative;
    top:0;
    min-height: 100%;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 40px;
}

.RegisterWorkForm {
    display: block;
    position: relative;
    width: 550px;
    height: 80%;
    left: 50%;
    /* top: 50%; */
    transform: translate(-50%, 0);
}

.RegisterWorkForm > h2 {
    display: block;
    color: #23A287;
    font-weight: normal;
    font-size: 36px;
    letter-spacing: 2px;
    margin-bottom: 0;
    height: 24px;
    line-height: 24px;
    white-space: nowrap;
    overflow: unset;
}
.RegisterWorkForm > h1 {
    display: block;
    color: #23A287;
    font-size: 36px;
    letter-spacing: 2px;
    margin-top: 30px;
    height: 24px;
    line-height: 24px;
    white-space: nowrap;
    overflow: unset;
}

.RegisterWorkFormCloseBt {
    position: absolute;
    display: block;
    width: 180px;
    height: 44px;
    background-color: transparent;
    cursor: pointer;
    color: #23A287;
    text-align: right;
    border: none;
    top: -40px;
    right: 0;
    padding: 0;
    line-height: 44px;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    overflow: unset !important;
}

.RegisterWorkFormCloseBt > svg {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translate(0, -50%);
    right: 90px;
}


.RegisterWorkFormBack {
    position: absolute;
    display: block;
    width: 180px;
    height: 44px;
    background-color: transparent;
    cursor: pointer;
    color: #23A287;
    margin-top: 50px;
    text-align: left;
    border: none;
    /*top: -40px;*/
    /*right: 0;*/
    /*left: 40px;*/
    padding: 0 0 0 35px;
    line-height: 44px;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
}

.RegisterWorkFormBack > svg {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 55%;
    transform: translate(0, -50%);
    left: 0;
}

.RegisterFormScroll {
    position: relative;
    display: block;
    width: 50% !important;
    height: 99% !important
}

.RegisterFormInputsNormal {
    width: 550px;
}

.RegisterFormInputsShort {
    width: 250px !important;
}

.RegisterGusBt {
    position: absolute !important;
    display: block !important;
    right: 0;
    top: 150px
}

.loginEnglishBt {
    position: absolute !important;
    top: 46px;
    right: -80px
}

@media screen and (max-height: 1000px) {
    .Login_Window {
        transform-origin: 0 0;
        transform: scale(.9) translate(-50%, -50%);
    }
    @media screen and (max-width: 1300px) {
        .Login_Window {
            transform-origin: 0 0;
            transform: scale(.8) translate(-50%, -50%);
        }
    }
}

@media screen and (max-width: 700px) {
    .Login_BackGround {
        width: 101%;
        height: 1200px;
        background-color: #1D3138;
    }

    .Login_BackGround > svg {
        z-index: 1;
    }
    .Login_BackGround .DarkButtons {
        background-color: #23A287 !important;
        z-index: 3 !important;
    }
    .Login_Window_image {
        position: relative;
        width: 90%;
        height: auto;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
        margin-bottom: 120px ;
    }
    .Login_Window_inputs {
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
        /* height: 00rem !important; */
    }
    .Login_Window {
        top: 0;
        transform: translate(-50%, 0);
        width: 100%;
        border-radius: unset;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 1000px;
        z-index: 2;
        background-color: transparent;
        box-shadow: none;
    }
    .Login_Window_powerby {
        left: 50%;
        transform: translate(-50%, 0);
        position: relative;
        margin-top: 100px;
    }
    .loginEnglishBt {
        position: absolute !important;
        top: 0;
        right: 5%;
    }
    .Login_Window_inputs_intput {
        width: 100% !important;
    }
}



.LoginInputsBox {
    /* 360px x 40.5px */
    position: relative;
    display: block;
    height: 40.5px;
    width: 360px;
    border-bottom: 1px solid #23A287;
    margin-bottom: 23px;
}

.LoginInputsBox > svg {
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    margin: 0;
    top: 50%;
    transform: translate(0 ,-50%);
    left: 13px;
}

.LoginInputsBox input {
    display: block;
    position: relative;
    width: 85%;
    height: 100%;
    background-color: transparent;
    outline: none;
    color: white;
    border: none;
    font-family: Poppins, serif;
    font-size: 16px;
    left: 46px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
    opacity: 1;
}
::-moz-placeholder { /* Firefox 19+ */
    color: white;
    opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
    color: white;
    opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
    color: white;
    opacity: 1;
}
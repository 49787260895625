.buttonnex {
    float: left;
    left: 900px;
    top: 50px
}

.buttonsend {
    float: right;
    margin-top: 30px;
}

.checkr_dash_first {
    display: block !important;
}

.DashBoardView_main_loadingPop_back {
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.616);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
}

.DashBoardView_main_loadingPop_pop {
    position: absolute;
    width: 500px;
    height: 170px;
    background-color: #1D3138;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.DashBoardView_main_loadingPop_pop > a {
    display: block;
    position: relative;
    color: white;
    width: 100%;
    height: 24px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
}

.DashBoardView_main_loadingPop_bar {
    position: absolute;
    width: 80%;
    height: 44px;
    background-color: white;
    border-radius: 33px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: white 2px solid;
    overflow: hidden;
}

.DashBoardView_main_loadingPop_bar a {
    position: absolute;
    color:#23A287;
    font-size: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-clip: text !important;
    background: linear-gradient(240deg, #23A287 0%, rgba(255,255,255,1) 0%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* filter: invert(1);
    mix-blend-mode: difference; */
}

/* .DashBoardView_main_loadingPop_bar > a::after {
    
    color: white;
    
    mix-blend-mode: difference;
} */


.DashBoardView_main_loadingPop_bar > div {
    position: relative;
    background-color: #23A287;
    height: 100%;
    width: 100%;
    transition: 1s;
    /* overflow: hidden; */
    /* border-radius: 10px; */
}

.DashBoardView_main_loadingPop_bar > div::after {
    content: "";
    position: absolute;
    background-color: #23A287;
    width: 10%;
    height: 37%;
    right: -2%;
    bottom: 0;
    border-radius: 20px;
}

.DashBoardView_main_loadingPop_bar > div::before {
    content: "";
    position: absolute;
    background-color: #23A287;
    width: 10%;
    height: 37%;
    right: -2%;
    top: 0;
    border-radius: 20px;
}


.DashBoardView_main_loadingPop_bar > div > div {
    position: absolute;
    width: 10%;
    z-index: 2;
    height: 26%;
    right: -9%;
    background-color: white;
    top: 50%;
    transform: translate(0, -50%);

    border-radius: 20px;
}

@media screen and (max-width: 1500px) {

    .buttonnex {
        float: right;
        left: unset;
        top: 100px;
    }
    .buttonsend {
        float: right;
        left: unset;
        top: 0px;
    }
    .DashBoardView_workform_select {
        margin-left: unset !important;
    }
    .DocFiles_main  {
        /* left: 570px !important;
        width: 240px !important;
        height: 350px !important; */
    }
    @media screen and (max-width: 1200px) {
        .DashBoardView_workform {
            left: 20px !important;
            transform: unset !important;

        }
        @media screen and (max-width: 1100px) {
            .DashBoardView_workform {
                left: 50% !important;
                transform: translate(-50%, 0) !important;

            }
            @media screen and (max-width: 910px) {
                .DashBoardView_workform {
                    left: 20px !important;
                    transform: unset !important;

                }
                @media screen and (max-width: 840px) {
                    .DashBoardView_workform_final {
                        position: fixed !important;
                        top: 50% !important;
                        width: fit-content !important;
                    }
                    .DashBoardView_workform_select {
                        padding-bottom: 50px !important;
                    }
                    .DocFiles_main  {
                        /* left: 0 !important;
                        top: 0 !important;
                        width: 360px !important;
                        position: relative !important; */
                    }
                }
            }
        }

    }
    @media screen and (max-width: 700px) {
        .DashBoardView_workformCloseBt {
            top: 0px !important;
        }
        .DashBoardView_workform_select {
            margin-left:  unset;
        }
        .checkr_dash_first {
            left: 20px;
        }
        .DashBoardView_workform {
            overflow: hidden;
            position: relative;
            width: 90% !important;
            left: 50% !important;
            transform: translate(-50%, 0) !important;
        }
        .DocFiles_main  {
            left: 50% !important;
            top: 0 !important;
            width: 90% !important;
            position: relative !important;
            transform: translate(-50%, 0) !important;
        }
        .DashBoardView_workform_select {
            width: 100% !important;
            height: 20% !important;
        }
        .Huge_input {
            width: 100% !important;
        }
        .DashBoardView_workform_final {
            top: 50% !important;
            width: 90% !important;
            position: absolute !important;
            z-index: 2;
        }
        .DashBoardView_workform_final h1 {

            text-align: center !important;
            font-size: 10vw !important;
        }
        .DashBoardView_workform_final b {
            /* text-align: center !important; */
            font-size: 7vw !important;
            margin-bottom: 50px !important;
        }
        @media screen and (max-width: 400px) {
            .DashBoardView_workform {
                margin-top: 70px;
            }
        }
    }

}
.VL_wrapper {
    position: relative;
    display: block;
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #23A287;
}

.VL_wrapper_table {
    position: relative;
    border-collapse: collapse;
    min-width: 100%;
    table-layout: fixed;
    /* border-radius: 10px; */
}

.VL_wrapper_table > thead {
    text-align: left;
    background-color: #23A287;
    color: white;
    height: 30px;
    user-select: none;
    border-radius: 10px;
}

.VL_wrapper_table > thead a {
    padding: 5px;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.VL_wrapper_table > thead a > svg {
    position: relative;
    display: inline-block;
}

.VL_wrapper_table > tbody > tr:not(.VL_wrapper_table_noIndexed) {
    background-color: hsla(167, 64%, 39%, 0.1);
    color: #23A287;
    font-weight: normal;
    height: 35px;
}

.VL_wrapper_table > tbody > tr > td:first-child {
    padding-left: 5px;
}

.Vl_nodata_prompt {
    position: relative;
    text-align: center;
    margin-top: 10px;
    color: #23A287;
    user-select: none;
}

.VL_wrapper_table_loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 3;
}

.VL_wrapper_table_loading > img {
    position: relative;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.VL_wrapper_table_main_collapse {
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.VL_wrapper_table_main_collapse > p {
    width: 95%;
    position: relative;
    /* display: block; */
    color: #23A287;
    margin-top: 10px;
    white-space: pre;
}

.VL_wrapper_table_main_collapse_submit {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.VL_wrapper_table_main_collapse_form {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* justify-content: stretch; */
}

.VL_wrapper_table_pagination {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    width: 90%;
    height: 30px;
    margin-top: 10px;
    gap: 10px;
}

.VL_wrapper_table_pagination > div {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px ;
}

.VL_wrapper_table_pagination > div > a {
    color: #23A287;
    cursor: pointer;
    width: 20px;
    border-radius: 5px;
    text-align: center;
}

.VL_wrapper_table_pagination_selected, .VL_wrapper_table_pagination > div > a:hover {
    background-color: hsla(167, 64%, 39%, 0.1);
}

.VL_wrapper_table_pagination_nonselected:hover {
    background-color: transparent !important;
    cursor: default !important;
}


.VL_wrapper_table_pagination > button:first-child > svg {
    transform: rotate(-180deg);
}

.VL_wrapper_table_pagination > button {
    background-color: #23A287;
    border-radius: 5px;
    border: transparent;
    cursor: pointer;
    height: 100%;

}

.VL_wrapper_table_pagination > button > svg {
    position: relative;
    display: block;
    height: 70%;
    width: auto;
    fill: white;
}
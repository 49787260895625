@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../Fonts/Poppins-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Poppins';
    font-weight: bold;
    src: local('Poppins-Bold'), url(../Fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: local('Poppins-SemiBold'), url(../Fonts/Poppins-SemiBold.ttf) format('truetype');
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

* {
    font-family: 'Poppins';
}

.DocFiles_main {
    display: inline-block;
    float: right;
    position: relative;
    max-width: 100%;
    width: 320px;
    height: 470px;
    border: 2px solid #23A287;
    border-radius: 10px;
    /* transition: .1s; */
    /* flex-basis: auto; */
}

.DocFiles_main_list {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}

.DocFiles_main_list_item {
    display: block;
    position: relative;
    width: 95%;
    height: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.DocFiles_main_list_item a {
    position: relative;
    display: inline-block;
    /* padding-left: 30px; */
    max-width: 50%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: transparent;
    line-height: 30px;
}

.DocFiles_main_area {
    position: absolute;
    opacity: 0;
    width: 99%;
    height: 99%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* background-color: red; */
    z-index: 99;
}

.DocFiles_main_list_item svg {
    position: absolute;
    fill: #23A287;
    display: block;
    top: 50%;
    transform: translate(0, -50%);
}

.DocFiles_main   input[type='file'] {
    /* display: none; */
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.DocFiles_main_drag {
    background-color: #23A287;
    cursor: copy;
}

.DocFiles_main_start {
    display: flex;
    position: absolute;
    width: 60%;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DocFiles_main_next {
    position: absolute;
    user-select: none;
    background-color: #23A287;
    width: 100%;
    height: 30px;
    bottom: -3px;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
    z-index: 3;
}

.DocFiles_main_next svg {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
}

.DocFiles_main_next a {
    position: relative;
    display: block;
    text-align: center;
    color: white;
    line-height: 30px;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.DocFiles_main_list_removebt {
    position: absolute;
    right: 10px;
    width: 25px;
    height: 25px;
}
.forgotPasswordInput {
    border-color:  white;
    color: white;
}

.forgotPasswordInput label {
    color: white;
}

.forgotPasswordInput input {
    color: white !important;
}

@media screen and (max-width: 700px) {
    .RegisterBackgroundFinal > p {
        width: 450px !important;
    }
}

@media screen and (max-width: 1200px) {
    .Register_BackGround {
        /* padding-top: 100px; */
        flex-direction: column;
        flex-shrink: 0;
        flex-wrap: 0;
        height: unset;
    }
    .RegisterBackground {
        width: 100%;
        height: 750px;
        padding-top: 20px;
    }
    .RegisterFormScroll {
        overflow: hidden;
        width: 100% !important
    }

    .RegisterFormScroll * {
        overflow: hidden;
    }
    .RegisterForm {
        width: 100%;
    }
}

@media screen and (max-width: 575px) {
    .RegisterBackgroundText p {
        width: 90%;
    }

    .RegisterBackgroundFinal {
        min-height: 620px !important;
    }
    .RegisterBackgroundText b {
        width: 90%;
    }

    .RegisterBackgroundText h1 {
        font-size: 12vw;
        margin-bottom: 10vw;
    }
    .forgotPasswordInput {
        width: 90% !important;
    }
    .RegisterFormInputsNormal {
        width: 90% !important;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .RegisterWorkForm {
        width: 100%;
        overflow: unset !important;
    }
    .RegisterWorkForm > h2 {
        font-size: 8vw;
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .RegisterWorkForm > h1 {
        font-size: 8vw;
        position: relative;
        width: 90%;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .RegisterFormchecbox a {
        font-size: 3vw !important;
    }
    .Register_BackGround .RedButtons {
        right: 20px;
        font-size: 3vw !important;
        width: 50vw !important;
    }
    .Register_BackGround .RedButtonsNext {
        right: 20px;
        font-size: 3vw !important;
        width: 50vw !important;
    }
    .RegisterFormInputsShort {
        width: 30% !important;
        left: 5%;
    }
    .RegisterGusBt {
        min-width: unset !important;
        right: 5%;
        width: 50vw !important;
        font-size: 3vw !important;
    }
    .Register_BackGround .DarkGreenButtons {
        min-width: unset !important;
        font-size: 3vw !important;
        width: 50vw !important;
    }
    .RegisterWorkFormBack {
        left: 5% !important;
    }
    .RegisterWorkFormCloseBt {
        right: 5% !important;
    }
    .RegisterBackgroundFinal h1 {
        font-size: 10vw !important;
    }
    .RegisterBackgroundFinal h2 {
        font-size: 4vw !important;
    }
    .RegisterBackgroundFinal p {
        position: relative;
        font-size: 3vw !important;
        width: 80% !important;
        /* left: 50%;
        transform: translate(-50%, 0); */
        text-align: center;
    }
    .RegisterBackgroundFinal a {
        position: relative;
        font-size: 3vw !important;
        width: 80% !important;
        /* left: 50%;
        transform: translate(-50%, 0); */
        text-align: center;
    }
    .RegisterBackgroundFinal .RedButtons {
        right:  unset !important;
    }
}
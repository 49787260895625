.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
    line-height: 20px;
    overflow: unset !important;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    /* background-color: transparent; */
    /* border: 2px solid #23A287; */
    /* outline-offset: 2px; */
    /* transform: translateZ(0); */
    border-radius: 8px;

}

.checkmark_border {
    position: absolute;
    top: -4px;
    left: -4px;
    height: 24px;
    width: 24px;
    border: 2px solid #23A287;
    border-radius: 10px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    /*background-color: #23A287;*/
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    /*outline: 1px solid #23A287;*/
    /* outline-offset: -2px; */
    background-color: #23A287;
    /* width: 4px; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: " ";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 2px;
    height: 2px;
    /* border: solid white; */
    /* border-width: 0 3px 3px 0; */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.WorkViewNormal_header_user_right {
    width: fit-content;
    display: inline !important;
    margin-left: 87px;
    /* opacity: 0.5; */
    user-Select: none;
    cursor: pointer;
    transition: .3s;
    white-space: nowrap
}

.Password_editBt {
    position: absolute;
    left: 198px;
    top: 5px
}

.email_editBt {
    position: absolute;
    left: 370px;
    top: 5px
}

.huge_intput_user {
    width: 550px;
    margin-top: 40px;
}

@media screen and (max-width: 800px) {

    .Password_editBt {
        position: relative;
        left: 0;
        top: 5px
    }
    .email_editBt {
        position: relative;
        left: 0;
        top: 5px
    }

    .WorkViewNormal {
        width: 95%;
        padding-left: 10px;
    }
    .UserDate_form {
        width: 100%;
    }
    .huge_intput_user {
        width: 90%;

    }
    .WorkViewNormal_header_user_right {
        display: block !important;
        margin-left: 0 !important;
    }
    .WorkViewNormal_header {
        top: -20px;
    }
    .WorkViewNormal_header h1 {
        font-size: 30px;
    }
    .UserDate_form h2 {
        font-size: 27px !important;
    }
}
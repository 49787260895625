.InputNormal_Box {
    display: block;
    position: relative;
    width: 550px;
    height: 44px;
    border-bottom: 1px solid #23A287;
    box-sizing: border-box;
    overflow: unset !important;
}

.InputNormal_Box_Search > svg {
    position: absolute;
    display: block;
    width: 22.5px;
    height: 22.5px;
    top: 50%;
    transform: translate(0, -50%);
}

.InputNormal_Box > label {
    position: absolute;
    display: block;
    font-size: 16px;
    transform-origin: 0 0;
    color: #23A287;
    width: 90%;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}

.InputNormal_Box_Search > label {
    left: 30px !important;
}

.InputNormal_err_label {
    color: #D94F5B !important;
    top: 0 !important;
    transform: translate(0, -50%) scale(.75) !important;
}

.InputNormal_Box > textarea {
    display: block;
    position: relative;
    width: 90%;
    /* min-height: 44px; */
    height: 100%;
    /* max-height: 88px; */
    left: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: transparent ;
    outline: none;
    border: none;
    font-size: 16px;
    color: #23A287;
    line-height: 44px;
    transition: all 0.2s ease-out;
    resize: none;
    overflow: hidden;
    /* border: 1px solid #23A287; */
}

.InputNormal_Box > input {
    display: block;
    position: relative;
    width: 90%;
    height: 100%;
    left: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: transparent ;
    outline: none;
    border: none;
    font-size: 16px;
    color: #23A287;
    line-height: 44px;
    transition: all 0.2s ease-out;
    resize: none;
}

.InputNormal_Box_Search > input {
    left: 30px !important;
}

.InputNormal_Box > a {
    display: block;
    position: relative;
    height: 100% !important;
    width: 90% !important;
    left: 20px !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 16px;
    color: #23A287;
    line-height: 44px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
}

@keyframes example {
    from {
        top: 50%;
        transform: translate(0, -50%) scale(1);
    }
    to {
        top: 0;
        transform: translate(0, -50%) scale(.75);
        opacity: .75;
    }
}

.InputNormal_Box_Active  {
    animation-name: example;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes example1 {
    from {
        top: 0;
        transform: translate(0, -50%) scale(.75);
    }
    to {
        top: 50%;
        transform: translate(0, -50%) scale(1);
    }
}

.InputNormal_Box_DeActive  {
    animation-name: example1;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.InputNormal_select {
    position: absolute;
    display: block;
    background-color: transparent;
    color: #23A287;
    border: none;
    font-size: 16px;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    width: 105px;
    height: 24px;
    opacity: .5;
    cursor: pointer;
}

.InputNormal_select > svg {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

.InputNormal_select_box {
    margin-top: 10px;
    position: absolute;
    top: 44px;
    background-color: white;
    border: 2px solid #23A287;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    z-index: 10;
}

.InputNormal_select_box_item {
    display: block;
    position: relative;
    width: 95%;
    height: 35px;
    left: 50%;
    transform: translate(-50%, 0);
    color: #1D3138;
    text-align: left;
    font-size: 16px;
    line-height: 35px;
    cursor: pointer;
    user-select: none;
}

.InputNormal_select_box_item:hover {
    background-color: #23A287;
    border-radius: 10px;
    color: white;
}

.InputNormal_select_box_item_selected {
    font-weight: bold;
}

.InputNormal_select_box_item > a {
    display: block;
    position: relative;
    height: 100%;
    width: 85%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.InputNormal_Box_calendar_box {
    position: absolute;
    display: block;
    z-index: 3;
    width: 100%;
    min-height: 220px;
    background-color: white;
    border: 2px solid #23A287;
    border-radius: 10px;
    top: 46px;
    padding-top: 18px;
    padding-bottom: 15px;
}

.InputNormal_Box_calendar_box_days {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(7, 33px);
    justify-content: center;
    column-gap: 5px;
    row-gap: 5px;
    position: relative;
    width: 245px;
    left: 50%;
    transform: translate(-50%, 0);
    height: fit-content;
    flex-direction: row;
    flex-wrap: wrap;
    color: #1D3138;
    align-items: center;
    gap: 2px;
    row-gap: 2px;
}

.InputNormal_Box_calendar_box_days_v1 {

    margin-top: 20px;
    display: flex;
    flex-direction: row;
    /* grid-template-columns: repeat(7, 33px); */
    justify-content: center;
    column-gap: 5px;
    row-gap: 5px;
    position: relative;
    width: 245px;
    left: 50%;
    transform: translate(-50%, 0);
    height: fit-content;
    flex-direction: row;
    flex-wrap: wrap;
    color: #1D3138;
    align-items: center;
    gap: 2px;
    row-gap: 2px;
    padding-bottom: 30px;
}

.InputNormal_Box_calendar_box_selector {
    position: relative;
    display: inline-flex;
    height: 100%;
    width: 100px;
    flex-direction: column;
}

.InputNormal_Box_calendar_box_selector > a{
    position: relative;
    text-align: center;
    line-height: 33px;
    height: 33px;
    color: #1D3138;
    width: 100%;
}

.InputNormal_Box_calendar_box_selector_selected {
    background-color: #23A287 !important;
    color: white !important;
    border-radius: 10px;
}

.InputNormal_Box_calendar_box_days > span {
    position: relative;
    display: block;
    width: 33px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    cursor: pointer;
    /* background-color: #D94F5B; */
}

.InputNormal_Box_calendar_box_days_selected {
    border-radius: 10px;
    background-color: #23A287 !important;
    color: white !important;
}

.InputNormal_Box_calendar_box_header {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    color: #1D3138 ;
}

.InputNormal_Box_calendar_box_header_names {
    font-family: 'Poppins-SemiBold' !important;
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
    left: 50%;
    width: 92%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
}

.InputNormal_Box_calendar_box_header > b > svg {
    position: absolute;
    /* display: block; */
    fill: #1D3138;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.InputNormal_Box_calendar_box_header b {
    display: block;
    position: relative;
    width: fit-content;
    left: 50%;
    /* height: 100%; */
    /* top: 50%; */
    transform: translate(-50%, 0);
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    cursor: pointer;
    line-height: 35px;
}

.InputNormal_Box_calendar_box_header > button {
    display: block;
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    border: none;
    top: 5px;
}

.InputNormal_Box_calendar_box_header_week {
    position: absolute;
    display: block;
    width: 100%;
    height: 99%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #23A287;
    opacity: .25;
    /* color: red; */
}

.InputNormal_Box_Search > button {
    position: absolute;
    background-color: transparent;
    border: none;
    right: 5px;
    bottom: -35px;
    color: #23A287;
    height: 24px;
    line-height: 24px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
}

.InputNormal_Box_Search > button > svg {
    position: absolute;
    left: -30px;
    height: 30px;
    width: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.InputNormal_Box_Search_filtres_box {
    position: absolute;
    z-index: 2;
    background-color: #23A287;
    width: 220px;
    border-radius: 10px;
    right: 0;
    top: 80px;
}

.InputNormal_Box_Search_filtres_box > div {
    position: relative;
    display: block;
    height: 50px;
    width: 90%;
    left: 50%;

    transform: translate(-50%, 0);
}

.InputNormal_Box_Search_filtres_box_checkbox {
    position: relative;
    top: 50%;
    transform: translate(0 , -50%);
    font-size: 15px;
}


.InputNormal_Box_Search_filtres_box_checkbox span {
    border-color: white;
    top: 50%;
    transform: translate(0, -50%);
}

.InputNormal_Box_Search_filtres_box_checkbox input:checked ~ .checkmark {
    background-color: white !important;
}
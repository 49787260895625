.TaskBar_main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 280px;
    height: 100vh;
    background-color: #1D3138;
    user-select: none;
    flex-shrink: 0;
}

.TaskBar_main_logo {
    display: block;
    position: relative;
    width: 220px;
    height: auto;
    margin-top: 44px;
    margin-bottom: 44px;
    left: 50%;
    transform: translate(-50%, 0);
}

.TaskBar_main_logomdr {
    position: relative;
    display: block;
    left: 50%;
    width: 204px;
    height: 43px;
    transform: translate(-50%, 0);
    /* bottom: 12px; */
    margin-bottom: 12px;
    margin-top: auto;
}

.TaskBar_main_selector {
    display: block;
    position: relative;
    /* margin-top: 105px; */
    left: 50%;
    transform: translate(-50%, 0);
    list-style: none;
    padding: 0;
    width: 80%;
}

.TaskBar_main_selector li {
    margin-bottom: 23px;
    padding: 10px 0 10px 0;
}

.TaskBar_main_selector a {
    display: block;
    position: relative;
    width: 100%;
    color: white;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
}

.TaskBar_main_logout {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

.TaskBar_main_selected {
    position: relative;
    background-color: white;
    color: #1D3138;
    border-radius: 50px;
    padding: 10px 0 10px 0;
}

.TaskBar_main_selected::after {
    display: block;
    position: absolute;
    content: "";
    background-color: white;
    right: -35px;
    width: 55px;
    height: 44.6px;
    top: 0;
}

.TaskBar_main_selected a {
    color: #1D3138;
}

@keyframes TaskBar_open {
    from {
        left: -100%;
    }
    to {
        left: 0px;
    }
}

.TaskBar_open_class  {
    animation-name: TaskBar_open;
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes TaskBar_close {
    from {
        left: 0px;
    }
    to {
        left: -100%;
    }
}

.TaskBar_close_class  {
    animation-name: TaskBar_close;
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@media screen and (max-width: 1100px) {

    .TaskBar_main {
        /* display: none; */
        position: fixed;
        z-index: 3;
        left: -100%;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 8px 0px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 8px 0px rgba(66, 68, 90, 1)
    }

    @media screen and (max-width: 700px) {
        .TaskBar_main {
            width: 70%;
        }
        .TaskBar_main_selected::after {
            display: none;
        }

        @media screen and (max-width: 400px) {
            .TaskBar_main {
                width: 100%;
            }
        }
    }
}
.MainViewBack {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;
}

.MainViewBack_showTaskBar {
    display: none;
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    transition: 1s;
}

.MainViewBack_showTaskBar_onScroll {
    background-color: #23A287 !important;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 6px 0px rgba(66, 68, 90, 1);
    z-index: 100 !important;
}

.MainViewBack_showTaskBar_onScroll > button {
    color: white !important;
}

.MainViewBack_showTaskBar_onScroll > button > svg *{
    fill: #ffffff  !important;
    stroke: #ffffff !important;
}

.MainViewBack_showTaskBar > button {
    display: block;
    position: relative;
    background-color: transparent;
    height: 24px;
    cursor: pointer;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
    color: #23A287;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 24px;
    cursor: pointer;
    z-index: 2;
    width: 100px;
    text-align: right;
    user-select: none;
}

.MainViewBack_showTaskBar > button > svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 0;
    /* left: -25px; */
    transform: translate(0, -50%);
}

@media screen and (max-width: 1100px) {
    .MainViewBack_showTaskBar {
        display: block;
    }
}

.DashBoardView_main {
    display: block;
    width: 100%;
    height: 100%;
}

.DashBoardView_workform {
    position: relative;
    width: 80%;
    max-width: 1300px;
    height: fit-content;
    margin-top: 139px;
    left: 50%;
    height: 50%;
    transform: translate(-50%, 0);
    color: #23A287;
    /* border: 1px solid red; */
}

.DashBoardView_workformCloseBt {
    position: absolute;
    display: block;
    width: 180px;
    height: 44px;
    background-color: transparent;
    cursor: pointer;
    color: #23A287;
    text-align: right;
    border: none;
    top: -40px;
    right: 0;
    padding: 0;
    line-height: 44px;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    overflow: unset !important;
}

.DashBoardView_workformCloseBt > svg {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translate(0, -50%);
    right: 90px;
}


.DashBoardView_workform > h2 {
    font-weight: lighter;
    /* padding-left: 70px; */
    letter-spacing: 2px;
    font-size: 36px;
}


.DashBoardView_workform > h1 {
    letter-spacing: 2px;
    /* padding-left: 70px; */
    font-size: 36px;
}

.DashBoardView_workform_select {
    position: relative;
    /* padding-top: 38px; */
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    gap: 50px;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    /* margin-left: 70px; */
}

.DashBoardView_workform_select > div {
    /* margin-top: 58px; */
    font-size: 16px;
}

.DashBoardView_workform_select > div > a {
    display: block;
    position: relative;
    width: 93%;
    /* left: 50%; */
    /* transform: translate(-50%,0); */
    /* margin-left: 35px; */
}

.DashBoardView_workform_final {
    display: block;
    position: relative;
    width: 50%;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.DashBoardView_workform_final h1 {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 64px;
    letter-spacing: 2px;
    margin: 0;
}

.DashBoardView_workform_final b {
    display: block;
    margin: 0;
    margin-top: -20px;
    text-align: center;
    width: 100%;
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: normal;
}

.WorkViewNormal {
    display: block;
    position: relative;
    /* width: 100%; */
    height: fit-content;
    padding-left: 52px;
    padding-top: 122px;
}

.WorkViewNormal > table {
    position: relative;
    border-collapse: collapse;
    margin-top: 78px;
    width: 90%;
    table-layout: fixed;
}

/* .WorkViewNormal * {
    transition: 1s !important;
} */

.WorkViewNormal > table > thead {
    background-color: #23A287;
    color: white;
    /* padding: 5px; */
    height: 30px;
}

.WorkViewNormal > table > thead a {
    display: block;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 30px;
    /* font-weight: normal; */
    /* font-size: 20px; */
    /* text-align: left; */
}

.WorkViewNormal > table > thead svg {
    display: inline;
    position: relative;
    /* right: 0; */
    /* line-height: 10px; */
    top: 6px;
    /* transform: translate(0, -50%); */
}

.WorkViewNormal > table > tbody > tr:nth-child(1n) {
    background-color: rgba(255, 255, 255, 0.1);
    color: #23A287;
    font-weight: normal;
}

.WorkViewNormal table > tbody > tr > th  {
    position: relative;
}

/* .WorkViewNormal table > tbody > tr {
    transition: 1s !important;
} */

.WorkViewNormal table > tbody > tr > th > table {
    table-layout: fixed;
}

.WorkViewNormal table > tbody > tr > th > a {
    display: block;
    /* min-width: 30px; */
    width: 100%;
    font-weight: normal;
    /* font-size: 20px; */
    /* text-align: left; */
}

.WorkViewNormal table > tbody > tr > th > svg {
    fill: #23A287;
}

.WorkViewNormal > table > tbody > tr {
    height: 40px;
}

.WorkViewNormal_selected {
    background-color: #23A287 !important;
    color: white !important;
    border: 1px solid white;
    box-sizing: border-box;
}

.WorkViewNormal_selected table {

    animation-name: example21;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: unset !important;
}

@keyframes example21 {
    from {
        height: 44px !important;
    }
    to {
        height: 204px !important;
    }
}


.WorkViewNormal_selected th > table > tbody > tr > th > svg {
    fill: white !important;
}

.WorkViewNormal_selected_hide_bt {
    position: relative;
    background-color: transparent;
    display: block;
    margin-left: 52px;
    /* bottom: 13px; */
    right: 38px;
    border: none;
    color: white;
    cursor: pointer;
    height: 44px;
    line-height: 44px;
    font-size: 20px;
    text-align: left;

}

.WorkViewNormal_selected_hide_bt > svg {
    position: absolute;
    width: 37px;
    height: 26px;
    top: 50%;
    transform: translate(0, -50%);
}

.WorkViewNormal_selected_files {
    width: 100%;
    position: relative;
    text-align: left;
}

.WorkViewNormal_selected_files > b {
    text-align: left;
    width: fit-content;
    position: relative;
    padding-left: 11px;
}

.WorkViewNormal_selected_files > div {
    position: relative;
    display: inline-flex;
    font-weight: normal;
    flex-direction: column;
    padding-left: 10px;
}

.WorkViewNormal_selected_files > div > div {
    position: relative;
    /* width: 90%; */
    /* left: 50%; */
    /* tra */
    height: 30px;
    cursor: pointer;
}

.WorkViewNormal_selected_files > div > div > span {
    line-height: 30px;
    padding-left: 30px;
    text-decoration: underline;
    font-weight: lighter;
}

.WorkViewNormal_selected_files > div > div > svg {
    position: absolute;
    left: 0;
}

.WorkViewNormal_selected_types {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
}

.WorkViewNormal_selected_types b {
    position: relative;
    text-align: left;
    width: fit-content;
}

.WorkViewNormal_selected_types a {
    margin-left: 13px;
    font-weight: normal;
}

.WorkViewNormal > table > tbody > tr:nth-child(2n) {
    background-color: hsla(167, 64%, 39%, 0.1);
    color: #23A287;
    font-weight: normal;
}

.WorkViewNormal_header {
    position: relative;
    display: block;
    width: 90%;
    height: 100px;
    color: #23A287;
}

.WorkViewNormal_header_search {
    display: block;
    position: absolute;
    width: 220px;
    top: 0;
    right: 0;
}

.WorkViewNormal_header h1 {
    display: block;
    position: relative;
    color: #23A287 !important;
    letter-spacing: 2px;
    font-size: 36px;
}

.DocumentSended_counters > a {
    color: #23A287;
    font-size: 32px;
    display: inline-block;
    margin-right: 11px;
}

.DocumentSended_counters > span {
    font-size: 20px;
}

.UserDate_form {
    display: inline-block;
    position: relative;
    margin-top: 37px;
    width: 500px;
}

.UserDate_form > h2 {
    color: #23A287;
    font-size: 32px;
    font-weight: normal;
}

.UserDate_inputNormal {
    border-color: white !important;
}

.UserDate_inputNormal_item_select:hover > a {
    color: #23A287 !important;
}

.UserDate_inputNormal * {
    color: white !important;
    font-weight: normal !important;
    border-color: white !important;
}

.UserDate_transparent_buttons {
    color: #23A287 !important;
    border: none !important;
    background-color: white !important;
    fill: #23A287 !important;
    padding-left: 0;
    /* padding-right: 30px; */
    /* text-align: right; */
}

.UserDate_transparent_buttons * {
    fill: #23A287 !important;
    /* text-align: right !important; */
    padding: 0 !important;
}

.UserDate_transparent_buttons svg {
    right: 0;
    left: 15%;
    width: 25px;
    height: 25px;
}

.UserDate_transparent_empty_bt {
    color: white !important;
    border-color: white !important;
}

.SendedDocuments_row_additionals_editable_inputs {
    color: white !important;
    border-color: white !important;
    text-align: left;
    font-weight: normal;
    margin-bottom: 18px;
}

.SendedDocuments_row_additionals_editable_inputs * {
    text-align: left;
    color: white !important;
}

